import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un mamelló central al capell de color terrós clar, dissociat cap als marges en esquames concèntriques, quasi llanoses i de color més clar. El capell mesura de 4 a 8 cm de diàmetre. Les làmines són escotades, blanquinoses i ventrudes i el peu, és cilíndric, de 5 a 10 cm x 4 a 12 mm i amb un anell poc definit i fugaç que marca per davall l’inici de l’aspecte llanut del mateix. Les espores són blanques en massa, fusiformes, de 13-14 x 4-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      